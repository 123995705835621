import React from "react";
import styles from "./style.module.scss";
import SvgIcons from "components/atoms/SvgRender/SvgRender";
import classNames from "classnames";

const CardItem: React.FC<any> = ({
  card,
  selectedCard,
  changeSelectedCard,
  enableCheckbox,
  handleSetDefault
}) => {
  const radioClass = classNames(
    styles.container,
    selectedCard === card.paymentMethodId && styles.container_active
  );
  const handleChangeRadio = (e: any) => {
    changeSelectedCard(e.currentTarget.value);
  };
  return (
    <div className={radioClass}>
      <div className={styles.container_box}>
        <div>
          <SvgIcons iconName={card.brand} />
        </div>
        <div className={styles.container_box_details}>
          <p className={styles.container_box_details_last4}>
            Visa ending in {card.lastFourDigits}
          </p>
          <p className={styles.container_box_details_exp}>
            Expiry {card?.monthOfExpiry}/{card.yearOfExpiry}
          </p>
          {!card.isDefault && (
            <p className={styles.container_box_details_default} onClick={() => handleSetDefault(card.paymentMethodId)}>
              Set as default
            </p>
          )}
        </div>
      </div>
      {enableCheckbox && <input
        type="radio"
        className={styles.container_box_radio}
        onChange={(e: any) => handleChangeRadio(e)}
        name="card"
        value={card.paymentMethodId}
        checked={card.paymentMethodId === selectedCard}
      />}
    </div>
  );
};

export default CardItem;
