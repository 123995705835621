import React, { useEffect } from "react";
import { useNavigate, } from "react-router-dom";
import style from "./../User/Auth/style.module.scss";
import Button from "../../components/atoms/Button/Button";
import theme from "../../assets/themes";
import constants from "utils/constants";
import { Icon, Loading } from "components";
import { getAllSysBillingPlan } from "store/slices/sys.slice";
import { useAppDispatch, useAppSelector } from "store/hooks";

// Startup
// https://identitymatrix-prod.chargifypay.com/subscribe/kncv47vsbsvk/identity-matrix-startup-package
// Pro
// https://identitymatrix-prod.chargifypay.com/subscribe/nn2kbvsthxbs/identity-matrix-pro-package
// Plus
// https://identitymatrix-prod.chargifypay.com/subscribe/sfbtw2k63hk8/identity-matrix-plus-package

const Pricing: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {data: sysBillingPlans, isLoading } = useAppSelector((state) => state.sys.billingPlan);
  useEffect(() => {
    if (localStorage.getItem("accessToken")) navigate("/dashboard");
    dispatch(getAllSysBillingPlan());
    const container = document.getElementById("bg-container");
    if (!container) return
    container.style.backgroundImage = `url(${theme["bgImage"]})`;
  }, []);
  return <div className={`${style.signup_container} sm:h-screen h-auto`} id="bg-container">
    {isLoading 
      ? <Loading height="auto" />
      : <>
        <div className={style.logo}><img className={style.img} src={theme["logo"]} alt="logo" onClick={() => navigate("/welcome")} /></div>
        <div className={`${style.container_wrapper} gap-8 sm:w-9/12`}>
          <div className="gap-5 flex flex-col text-center text-base w-9/12">
            <h1 className="text-2xl font-bold tracking-wide">The <span className="text-primary-400">Simplest Pricing</span> You'll Ever See</h1>
            <p className="text-gray-300">We're sick of contracts and we know you are too, that's why <span className="text-primary-400">our pricing is simple</span>. No hidden fees, no needed upgrades, and no needed add-ons. With each credit you buy, you can use that credit to uncover a lead from your website with Pulse or find information on anyone with Reveal.</p>
            <p className="text-primary-400 text-xl font-bold capitalize">Any plan is free for {constants.FREE_TRIAL_DAYS} days!</p>
          </div>
          <div className="flex gap-3 sm:gap-10">
            {sysBillingPlans.map((plan, index) => {
              return <div className="flex flex-col gap-6 py-5 px-4 text-center border border-white-500 rounded-lg" key={index}>
                <h2 className="text-3xl font-bold tracking-wide">{plan.name}</h2>
                <div className="flex items-end justify-center gap-2">
                  <p className="text-primary-400 text-4xl font-bold">${plan.priceInCents / 100}</p>
                  <p className="text-white-500">/ month</p>
                </div>
                <p className="mx-auto w-9/12 text-sm text-white-500">{plan.description}</p>
                <div className="flex flex-col justify-center gap-4">
                  <div className="flex justify-center"><div className="badge sm primary text-sm">
                    <Icon name="check-circle" size={17} className="text-white" />
                    {plan.subtitle}  
                  </div></div>
                  <div className="flex justify-center"><div className="badge sm primary text-sm">
                    <Icon name="check-circle" size={17} className="text-white" />
                    {plan.credits} Reveal Credits
                  </div></div>
                </div>
                <p className="mx-auto w-9/12 text-sm text-white-500 italic">Reveal Credits are used for showing full contact info, exporting, and integration push.</p>
                <Button type="secondary" action="button" size="lg" className="w-full" onClick={() => window.location.href = plan.gatewayUrl}>
                  <b>START {constants.FREE_TRIAL_DAYS}-DAY FREE TRIAL</b>
                </Button>  
              </div>
            })}
        </div></div>
    </>}
  </div>
};
export default Pricing