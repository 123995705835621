import React from "react";
import Icon from "../Icon/Icon";

const LeadScore: React.FC<{ value: number }> = ({ value }) => {
  if (value === undefined) value = 0;
  const level = value <= 29 ? "low" : value <= 59 ? "medium" : "high"
  const style = level === "high" ? `bg-[#2B3637] text-[#5EC0A3]` : level === "medium" ? `bg-[#3B342C] text-[#E6A457]` : `bg-[#3B2C30] text-[#EB5C5C]`
  return (
    <div className={`p-2 rounded-xl flex gap-2 items-center text-sm ${style}`}>
      <Icon size={19} name={ level === "high" ? `star` : level === "medium" ? `face-meh` : `thumbs-down`} />
      {value}
    </div>
  );
}
export default LeadScore;