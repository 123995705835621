import React from "react";
import Card from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
// more
// https://codesandbox.io/s/ovvwzkzry9?file=/App.js:663-998
const PaymentCard: React.FC<{ 
  number: string,
  expiry: string,
  cvc: string,
  name: string,
  focused: string
 }> = ({
  number, expiry, cvc, name, focused
}) => {
  return <Card
    number={number}
    expiry={expiry}
    cvc={cvc}
    name={name}
    focused={focused as any}
  />
}
export default PaymentCard;