import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  addCard,
  getCards,
  handlePay,
  resetAddCard,
  resetPayment,
  resetSetDefaultCard,
  setDefaultPaymentMethod,
} from "store/slices/card.slice";
import Loading from "components/atoms/Loading/Loading";
import classNames from "classnames";
import CardItem from "../CardItem/CardItem";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import SegmentAgent from "apis/segmentAgent";
import { Button, Icon, Heading, InputField } from "components";
import constants from "utils/constants";
import { createSubscription, resetSubscriptionSlice, updateSubscription } from "store/slices/subscription.slice";
import { PaymentPlanType } from "utils/enums/paymentENUM";
import { useAppContext } from "utils/context/AppContext";
import PaymentCard from "components/atoms/PaymentCard/PaymentCard";
import PaymentHelper from "helpers/PaymentHelper";
import { ICard } from "types/card/card.types";
import { getPersonalInfo } from "store/slices/user.slice";
const PaymentMethod: React.FC<any> = ({
  className,
  classBody,
  noHeader,
  heightLimited,
  enableCheckbox,
  showPay,
  isInModal,
  numberOfCredits,
  amount,
  isSubscription,
  frequency,
  isEditing,
  subscriptionData,
}) => {
  const { app } = useAppContext();
  const location = useLocation();
  const { data: cards, isLoading, isSuccess: cardSuccess, defaultCard } = useAppSelector((state) => state.cards);
  const { data: coupon } = useAppSelector((state) => state.coupon);
  const { data: user } = useAppSelector((state) => state.user);
  const { isLoading: loadingAddCard, errorData: errorAddCard, isSuccess: addCardSuccess, } = useAppSelector((state) => state.cards.addCard);
  const { isLoading: setDefaultLoading, isSuccess: setDefaultSuccess, errorData: setDefaultError, } = useAppSelector((state) => state.cards.setDefaultCard);
  const { isLoading: paymentLoading, isSuccess: paymentSuccess, errorMessage: paymentError, data: dataHandlePay, } = useAppSelector((state) => state.cards.payment);
  const {isSuccess: isSubCreateSuccess, isLoading: isSubCreateProcessing, errorMessage: SubCreateErrorMsg } = useAppSelector((state) => state.subscription.createSubscription);
  const {isSuccess: isSubUpdateSuccess, isLoading: isSubUpdateProcessing} = useAppSelector((state) => state.subscription.updateSubscription);
  const dispatch = useAppDispatch();
  const [saveNewMethod, setSaveNewMethod] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<string>("");
  const [cardForm, setCardForm] = useState({ number: '4242424242424242', expiry: '05/25', cvc: '333', name: 'Jean Hoffmann', focus: '', });
  const isCardFormFilled = cardForm.number && cardForm.expiry && cardForm.cvc && cardForm.name ? true : false;
  const handleInputChange = (evt: any) => {
    const { name, value } = evt.target;
    if (name === "number") {
      evt.target.value = PaymentHelper.formatCreditCardNumber(value);
    } else if (name === "expiry") {
      evt.target.value = PaymentHelper.formatExpirationDate(value);
    } else if (name === "cvc") {
      evt.target.value = PaymentHelper.formatCVC(value);
    }
    setCardForm({ ...cardForm, [name]: evt.target.value });
  }
  const handleInputFocus = (evt: any) => {
    setCardForm((prev) => ({ ...prev, focus: evt.target.name }));
  }
  const handleSetDefault = (paymentMethodId: string) => {
    if (paymentMethodId) return dispatch(setDefaultPaymentMethod(paymentMethodId));
    toast.warning("Something goes wrong...");
  };
  const handleAddCard = async () => {
    if (!isCardFormFilled) {
      toast.warn("Please fill in all the payment card fields", { toastId: "payment_card_error" });
      return null
    }
    if (!PaymentHelper.isPaymentCardValid(cardForm)) {
      toast.warn("Please check your card details and try again", { toastId: "payment_card_error" });
      return null
    }
    await dispatch(addCard(cardForm));
    // const { paymentMethod, error } = await stripe.createPaymentMethod({ type: "card", card: elements.getElement(CardCvcElement, CardExpiryElement, CardNumberElement) });
    // if (paymentMethod) {
    //   if (cards.length === 0 && showPay) setIsProcessingPayment(true);
    //   const cardPrepared = prepareForSaveCard(paymentMethod);
    //   await dispatch(addCard(cardPrepared));
    //   return cardPrepared.id;
    // } else {
    //   toast.warning(error && error?.code === "card_declined" ? `Oh snap! Your credit card was declined. Please try another one` : "Please check your card details and try again", { toastId: "payment_card_error" })
    //   return null;
    // }
    return null
  }
  const pay = async () => {
    if (!numberOfCredits) return toast.warn("Amount is missing");
    if (numberOfCredits < constants.CREDITS_PURCHASE_LIMIT_MINIMUM) return toast.warn(`The minimum amount of credits you can purchase is ${constants.CREDITS_PURCHASE_LIMIT_MINIMUM}.`);
    let cardPaymentMethodId: string | null = selectedCard;
    if (cards.length === 0) cardPaymentMethodId = await handleAddCard();
    if (!cardPaymentMethodId) return toast.warn("Please select a payment card", { toastId: "payment_card_error" });
    if (!isProcessingPayment) setIsProcessingPayment(true);
    if (!isSubscription) {
      dispatch( handlePay({
        amount, 
        numberOfCredits, 
        gatewayPaymentMethodId: cardPaymentMethodId, 
        couponId: coupon?.id, 
        appScheme: app.scheme 
      }) );
      return
    }
    dispatch(
      !isEditing
        ? createSubscription({
          amount: frequency === PaymentPlanType.YEARLY ? amount * 12 : amount,
          numberOfCredits,
          gatewayPaymentMethodId: cardPaymentMethodId,
          frequency,
          couponId: coupon?.id
        })
        : updateSubscription({
          amount: frequency === PaymentPlanType.YEARLY ? amount * 12 : amount,
          numberOfCredits,
          gatewayPaymentMethodId: cardPaymentMethodId,
          frequency,
          subscriptionData,
          couponId: coupon?.id
        })
    )
  };
  useEffect(() => {
    if (isInModal && location.pathname === "/user/billing") return;
    if (addCardSuccess) {
      if (user && user.segmentUserId) {
        SegmentAgent.event({}, user.segmentUserId, "PAYMENT_METHOD_ADDED", user.platformBrandId);
      }
      if ((showPay && cards.length > 0) || !showPay) {
        toast.success("Your card has been added successfully 🎉");
        dispatch(getCards());
        setSaveNewMethod(false);
        dispatch(getPersonalInfo())
        dispatch(resetAddCard());
      }
    } else if (errorAddCard) {
      toast.error(String(typeof errorAddCard === "object" ? errorAddCard.message : errorAddCard || "Something went wrong"))
      dispatch(resetAddCard());
    }
  }, [loadingAddCard]);
  useEffect(() => {
    if (cardSuccess && defaultCard) { setSelectedCard(defaultCard.paymentMethodId); }
  }, [isLoading]);
  useEffect(() => {
    if (setDefaultSuccess) {
      toast.success("Your default card changed", {toastId: "default-card-success"});
      dispatch(resetSetDefaultCard());
      dispatch(getCards());
    } else if (setDefaultError?.errorMessage) {
      toast.error(setDefaultError?.errorMessage);
      dispatch(resetSetDefaultCard());
      setIsProcessingPayment(false);
    }
  }, [setDefaultSuccess, setDefaultLoading]);
  const confirmPayment = async () => {
    if (amount === undefined) return;

    setTimeout(() => {
      window.open(`/user/billing/success?amount=${numberOfCredits}`, "_self");
    }, 2000);

    // const { error } = await stripe.confirmPayment({
    //   clientSecret: secret,
    //   confirmParams: {
    //     return_url: `${baseUrl}/user/billing/success?amount=${numberOfCredits}`,
    //   },
    // });
    // if (error) {
    //   toast.error("Payment was declined", { toastId: "payment-error" });
    //   setIsProcessingPayment(false);
    // }
  };
  useEffect(() => {
    if (SubCreateErrorMsg) {
      toast.error(String(SubCreateErrorMsg), { toastId: "subscription-error" });
      setIsProcessingPayment(false);
      dispatch(resetSubscriptionSlice());
      return
    }
    if (isSubCreateSuccess || isSubUpdateSuccess) {
      setIsProcessingPayment(false);
      toast.success(isSubCreateSuccess ? "You are now subscribed 🚀" : "Your subscription plan has been updated", { toastId: "subscription-success" });
      dispatch(resetSubscriptionSlice());
      setTimeout(() => {
        window.open(`/user/billing`, "_self");
      }, 800);
    }
    if (paymentSuccess && dataHandlePay && !isSubscription) {
      confirmPayment();
    } else if (paymentError) {
      toast.error(String(paymentError), { toastId: "payment-error" });
      dispatch(resetPayment());
      setIsProcessingPayment(false);
    }
  }, [paymentLoading, isSubCreateSuccess, isSubUpdateSuccess, SubCreateErrorMsg]);
  if (isLoading) return <Loading height="max-height" />
  return <div className={classNames(styles.main, className)}>
    {!noHeader && <Heading icon="card" title="Billing Methods" description="Add, update, or remove your billing methods." />}
    <div className={`${styles.main_container} ${classBody}`}>
      {saveNewMethod || !cards.length ? (
        <div className={styles.main_container_card}>
          {loadingAddCard || paymentLoading || isProcessingPayment 
            ? <Loading height="100px" />
            : <>
                <PaymentCard number={cardForm.number} expiry={cardForm.expiry} cvc={cardForm.cvc} name={cardForm.name} focused={cardForm.focus as any} />
                <form className="flex flex-col gap-4 mt-3">
                  <div className="gap-2 flex flex-col">
                    <label>Card Number</label>
                    <InputField
                      type="text" name="number" iconName="card" maxLength={22}
                      value={cardForm.number} onChange={handleInputChange} onFocus={handleInputFocus}
                    />
                  </div>
                  <div className="flex gap-3">
                    <div className="gap-2 flex flex-1 flex-col">
                      <label>Expiration</label>
                      <InputField
                        type="text" name="expiry" iconName="calendar-alt" placeholder="MM/YY" maxLength={5}
                        value={cardForm.expiry} onChange={handleInputChange} onFocus={handleInputFocus}
                      />
                    </div>
                    <div className="gap-2 flex flex-1 flex-col">
                      <label>CVC</label>
                      <InputField
                        type="text" name="cvc" iconName="lock"
                        value={cardForm.cvc} onChange={handleInputChange} onFocus={handleInputFocus}
                      />
                    </div>
                  </div>
                  <div className="gap-2 flex flex-col">
                    <label>Cardholder</label>
                    <InputField
                      type="text" name="name" iconName="user-check" maxLength={64} placeholder="Name of cardholder"
                      value={cardForm.name} onChange={handleInputChange} onFocus={handleInputFocus}
                    />
                  </div>
                </form>
              </>
          }
        </div>
      ) : (
        <div className={ heightLimited ? `${styles.main_container_allCards} ${styles.main_container_allCards_heightLimited}` : styles.main_container_allCards}>
          {setDefaultLoading 
            ? <Loading height="max-content" />
            : cards?.map(
                (card: ICard): JSX.Element => (
                  <CardItem
                    key={card.paymentMethodId}
                    card={card}
                    selectedCard={selectedCard}
                    enableCheckbox={enableCheckbox}
                    changeSelectedCard={setSelectedCard}
                    handleSetDefault={handleSetDefault}
                  />
                )
            )}
        </div>
      )}
      {saveNewMethod || !cards.length ? <>
        {((showPay && cards.length > 0) || saveNewMethod || (!showPay && !cards.length)) && (
          <div
            className={styles.main_container_card_actions}
            style={{ display: "flex", justifyContent: saveNewMethod || (isInModal && !cards.length) || isInModal ? "space-between" : "flex-end", }}
          >
            {(saveNewMethod || (!cards.length && isInModal) || isInModal) && (
              <div
                className="text-white-500 bg-gray-600 rounded-full w-8 h-8 flex items-center justify-center cursor-pointer hover:opacity-70"
                onClick={() => setSaveNewMethod(false)}
              >
                <span className="pr-0.5"><Icon name="chevron-left" size={17} /></span>
              </div>
            )}
            <Button disabled={!isCardFormFilled || loadingAddCard} onClick={handleAddCard}>Save payment method</Button>
          </div>
        )}
      </>
      : <div className={styles.main_container_allCards_actions}>
          <Button type="download" iconName="plus" onClick={() => setSaveNewMethod(true)}>Add new payment method</Button>
        </div>
      }
      {showPay && (
        <Button
          onClick={pay} style={{ width: "100%", fontWeight: "bold" }} size="lg"
          isProcessing={paymentLoading || isProcessingPayment || isSubCreateProcessing || isSubUpdateProcessing}
        >
          {isSubscription ? `Confirm Subscription` : `Confirm Payment`}
        </Button>
      )}
    </div>
  </div>
};

export default PaymentMethod;
