import React, { useEffect } from "react";
import styles from "./style.module.scss";
// import PaymentMethod from "components/molecules/PaymentMethod/PaymentMethod";
// import { Elements } from "@stripe/react-stripe-js";
// import getStripe from "utils/getStripe";
import Transactions from "../../components/molecules/Transactions/Transactions";
import SegmentAgent from "apis/segmentAgent";
import { useLocation } from "react-router-dom";
import { Heading } from "components";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getCards } from "store/slices/card.slice";
import SubscriptionInfo from "components/molecules/SubscriptionInfo/SubscriptionInfo";
// import { useAppContext } from "utils/context/AppContext";

const Billing: React.FC = () => {
  // const { app } = useAppContext();
  const location = useLocation();
  const { data: user } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    SegmentAgent.page("billingPayment", { pathName: location.pathname });
    dispatch(getCards());
  }, []);
  return (
    <div className={styles.main}>
      {/* {!user?.isInvitedUser && (
        <Elements stripe={getStripe(app?.stripePublicKey)}>
          <PaymentMethod classBody="p-5 bg-paper-500" />
        </Elements>
      )} */}
      <SubscriptionInfo />
      <div className={styles.main_transactions}>
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between">
          <Heading
            title="Transaction History"
            icon="document" iconSize={30}
            description={`${user.creditBalance || 0} credits`}
          />
        </div>
        <Transactions />
      </div>
    </div>
  );
};

export default Billing;
