import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { IUIState } from "types/ui/ui.type";

const initialState: IUIState = {
  currentPage: null,
  triggerApplyFilters: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTriggerApplyFilters: (state, action) => {
      state.triggerApplyFilters = action.payload;
    },
  },
});

export const { setCurrentPage, setTriggerApplyFilters } = uiSlice.actions;
