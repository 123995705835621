import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
import { SysState } from "types/sys.type";

const baseUrl = process.env.REACT_APP_BASE_URL;

const initialState: SysState = {
  billingPlan: {
    isLoading: false,
    isSuccess: false,
    errorMessage: "",
    data: [],
  },
};
export const getAllSysBillingPlan = createAsyncThunk("sysBillingPlan/Get", async (_, thunkAPI) => {
  try {
    const response = await axios.get(`${baseUrl}sys/billing_plan/plans`, {withCredentials: true});
    return thunkAPI.fulfillWithValue(response.data);
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
export const sysSlice = createSlice({
  name: "sys", initialState,
  reducers: {
    resetSysBillingPlan: (state) => {
      state.billingPlan.errorMessage = "";
      state.billingPlan.isLoading = false;
      state.billingPlan.isSuccess = false;
      state.billingPlan.data = [];
  }},
  extraReducers: (builder) => {
    builder.addCase(getAllSysBillingPlan.fulfilled, (state, action) => {
      state.billingPlan.isLoading = false;
      state.billingPlan.isSuccess = true;
      state.billingPlan.errorMessage = "";
      state.billingPlan.data = action.payload.data;
    });
    builder.addCase(getAllSysBillingPlan.rejected, (state, action: any) => {
      state.billingPlan.data = [];
      state.billingPlan.isLoading = false;
      state.billingPlan.isSuccess = false;
      state.billingPlan.errorMessage = action.payload?.message || "Something went wrong";
    });
    builder.addCase(getAllSysBillingPlan.pending, (state) => { state.billingPlan.isLoading = true; });
  },
});
export const { resetSysBillingPlan } = sysSlice.actions;
export default sysSlice.reducer;